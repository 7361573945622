var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-app-bar',{staticClass:"grey lighten-3",attrs:{"bottom":"","hide-on-scroll":"","app":""}},[_c('v-btn',{staticClass:"d-none d-md-block grey lighten-3",attrs:{"depressed":"","height":"110%"},on:{"click":function($event){return _vm.$router.push('/')}}},[_c('v-img',{staticClass:"mr-2",attrs:{"alt":"Photography Logo","contain":"","src":require("../assets/logo2.png"),"transition":"scale-transition","width":"100"}})],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"d-none d-md-block",attrs:{"text":"","active-class":"grey lighten-3","width":"13%","height":"110%"}},on),[_vm._v("Wedding")])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.$router.push('/wedding').catch(function (err) {})}}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Thodoris Sofia")])],1),_c('v-list-item',{on:{"click":function($event){_vm.$router.push('/wedding2').catch(function (err) {})}}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Sevastianos Marianna ")])],1)],1)],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"d-none d-md-block",attrs:{"text":"","active-class":"grey lighten-3","width":"14%","height":"110%"}},on),[_vm._v("Christening")])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.$router.push('/christening').catch(function (err) {})}}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Manolis")])],1),_c('v-list-item',{on:{"click":function($event){_vm.$router.push('/christening2').catch(function (err) {})}}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Nikitas")])],1)],1)],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"d-none d-md-block",attrs:{"text":"","active-class":"grey lighten-3","width":"14%","height":"110%"}},on),[_vm._v("Digital Albums")])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.$router.push('/Digitalalbum').catch(function (err) {})}}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Dimitris Voula")])],1),_c('v-list-item',{on:{"click":function($event){_vm.$router.push('/Digitalalbum2').catch(function (err) {})}}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Thodoris Sofia")])],1),_c('v-list-item',{on:{"click":function($event){_vm.$router.push('/Digitalalbum3').catch(function (err) {})}}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Aggelos")])],1),_c('v-list-item',{on:{"click":function($event){_vm.$router.push('/Digitalalbum4').catch(function (err) {})}}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Alexandros")])],1)],1)],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"d-none d-md-block",attrs:{"text":"","active-class":"grey lighten-3","width":"14%","height":"110%"}},on),[_vm._v(" Other Photo "),_c('br'),_vm._v("Projects ")])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.$router.push('/other').catch(function (err) {})}}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Acropolis Vision")])],1),_c('v-list-item',{on:{"click":function($event){_vm.$router.push('/other2').catch(function (err) {})}}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Airbnb House")])],1),_c('v-list-item',{on:{"click":function($event){_vm.$router.push('/other3').catch(function (err) {})}}},[_c('v-list-item-title',{staticClass:"text-center"},[_vm._v("Happy Family")])],1)],1)],1),_c('v-btn',{staticClass:"d-none d-md-flex",attrs:{"text":"","active-class":"grey lighten-3","width":"11%","height":"110%","to":"/about"}},[_vm._v("about")]),_c('v-btn',{staticClass:"d-none d-md-flex",attrs:{"text":"","active-class":"grey lighten-3","width":"12%","height":"110%","to":"/contact"}},[_vm._v("contact")]),_c('a',{staticClass:"d-none d-md-block",attrs:{"href":"//www.facebook.com/k.andrisphoto"}},[_c('v-btn',{attrs:{"icon":"","width":"110"}},[_c('v-icon',{attrs:{"color":"black","large":""}},[_vm._v("mdi-facebook")])],1)],1),_c('a',{staticClass:"d-none d-md-block",attrs:{"href":"//www.instagram.com/k.andrisphoto/"}},[_c('v-btn',{attrs:{"icon":"","width":"110"}},[_c('v-icon',{attrs:{"color":"black","large":""}},[_vm._v("mdi-instagram")])],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({staticClass:"d-md-none",attrs:{"light":"","width":"110"}},on))]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-list',{attrs:{"subheader":"","tile":"","dense":""}},[_c('v-list-item',{attrs:{"to":"/"},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-title',{staticClass:"barText"},[_vm._v(" Home")])],1)],1),_c('v-list',{attrs:{"subheader":"","tile":"","dense":""}},_vm._l((_vm.items),function(item){return _c('v-list-group',{key:item.title,staticClass:"barText",attrs:{"prepend-icon":item.action,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},_vm._l((item.items),function(subItem){return _c('v-list-item',{key:subItem.title,on:{"click":function($event){_vm.$router.push(subItem.link);
              _vm.isOpen = !_vm.isOpen;}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(subItem.title))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(subItem.action))])],1)],1)}),1)}),1),_c('v-list',{attrs:{"subheader":"","tile":"","dense":""}},[_c('v-list-item',{staticClass:"barText",attrs:{"to":"/About"},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-information-outline")])],1),_c('v-list-item-title',[_vm._v("About")])],1)],1),_c('v-list',{staticClass:"barText",attrs:{"subheader":"","tile":"","dense":""}},[_c('v-list-item',{attrs:{"to":"/Contact"},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-card-account-mail")])],1),_c('v-list-item-title',[_vm._v("Contact")])],1)],1)],1),_c('v-spacer'),_c('a',{staticClass:"d-md-none",attrs:{"href":"//www.facebook.com/k.andrisphoto"}},[_c('v-btn',{attrs:{"icon":"","width":"110"}},[_c('v-icon',{attrs:{"color":"black","large":""}},[_vm._v("mdi-facebook")])],1)],1),_c('a',{staticClass:"d-md-none",attrs:{"href":"//www.instagram.com/k.andrisphoto"}},[_c('v-btn',{attrs:{"icon":"","width":"110"}},[_c('v-icon',{attrs:{"color":"black","large":""}},[_vm._v("mdi-instagram")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }