import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Christening from "../views/Christening.vue";
import Christening2 from "../views/Christening2.vue";
import Contact from "../views/Contact.vue";
import Digitalalbum from "../views/Digitalalbum.vue";
import Digitalalbum2 from "../views/Digitalalbum2.vue";
import Digitalalbum3 from "../views/Digitalalbum3.vue";
import Digitalalbum4 from "../views/Digitalalbum4.vue";
import Wedding from "../views/Wedding.vue";
import Wedding2 from "../views/Wedding2.vue";
import About from "../views/About.vue";
import Other from "../views/Other.vue";
import Other2 from "../views/Other2.vue";
import Other3 from "../views/Other3.vue";
import VueGtag from "vue-gtag";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/christening",
    name: "christening",
    component: Christening,
  },
  {
    path: "/christening2",
    name: "christening2",
    component: Christening2,
  },
  {
    path: "/digitalalbum",
    name: "Digital album",
    component: Digitalalbum,
  },
  {
    path: "/digitalalbum2",
    name: "Digital album2",
    component: Digitalalbum2,
  },
  {
    path: "/digitalalbum3",
    name: "Digital album3",
    component: Digitalalbum3,
  },
  {
    path: "/digitalalbum4",
    name: "Digital album4",
    component: Digitalalbum4,
  },
  {
    path: "/wedding",
    name: "Wedding",
    component: Wedding,
  },
  {
    path: "/wedding2",
    name: "Wedding2",
    component: Wedding2,
  },
  {
    path: "/other",
    name: "Other",
    component: Other,
  },
  {
    path: "/other2",
    name: "Other2",
    component: Other2,
  },
  {
    path: "/other3",
    name: "Other3",
    component: Other3,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
];

Vue.use(
  VueGtag,
  {
    config: { id: "G-F5MT1RJ9D9" },
  },
  router
);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
