<template>
  <div class="about">
    <v-img
      src="../assets/img13.jpg"
      max-height="160px"
      contain
      @click="$router.push('/')"
    >
      <v-spacer></v-spacer> <v-divider></v-divider>
      <v-row align="end" class="lightbox white--text pa-2 fill-height">
        <v-col>
          <v-container> </v-container>
        </v-col>
      </v-row>
    </v-img>
    <v-col cols="12">
      <v-card elevation-20 class="mx-auto" outlined color="grey lighten-4">
        <v-list-item>
          <v-list-item-content>
            <v-col>
              <div class="text-md-h5 text-sm-h6 text-caption text-center">
                Για κάποιους η φωτογραφία είναι χόμπι,
              </div>
              <div class="text-md-h5 text-sm-h6 text-caption text-center">
                για άλλους είναι μέσο έκφρασης,
              </div>
              <div class="text-md-h5 text-sm-h6 text-caption text-center">
                για μερικούς είναι τρόπος ζωής
              </div>
            </v-col>
            <div class="text-md-h5 text-sm-h6 text-caption text-center mt-5">
              Στη δική μου τη ματιά η φωτογραφία παγώνει το χρόνο
            </div>
            <div class="text-md-h5 text-sm-h6 text-caption text-center">
              και κάνει μια στιγμή να κρατήσει για πάντα.
            </div>
            <div class="text-md-h5 text-sm-h6 text-caption text-center mt-5">
              Στόχος μου είναι να φυλάξει καλά το συναίσθημα της στιγμής.
            </div>
            <div class="text-md-h5 text-sm-h6 text-caption text-center">
              Έτσι, κάθε φορά που θα ξαναβλέπεις τις φωτογραφίες σου,
            </div>
            <div class="text-md-h5 text-sm-h6 text-caption text-center">
              θα το ζεις και θα το νιώθεις ξανά και ξανά.
            </div>
            <div class="text-md-h5 text-sm-h6 text-caption text-center mt-5">
              Με μπόλικο αυθορμητισμό και χωρίς ιδιαίτερες παρεμβάσεις,
            </div>
            <div class="text-md-h5 text-sm-h6 text-caption text-center">
              καταφέρνουμε μαζί ένα αποτέλεσμα όμορφο, φυσικό και πέρα ως πέρα
              αληθινό.
            </div>
          </v-list-item-content>

          <v-avatar tile size="23%" min-height="250" min-width="130">
            <v-img class="ml-10" contain :src="require(`../assets/flou.jpg`)">
            </v-img>
          </v-avatar>
        </v-list-item>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import '../app.scss';
export default {};
</script>

<style scoped>
.v-application {
  font-family: 'alfios' !important;
}
.v-headline {
  font-family: 'alfios' !important;
}
.v-application .headline {
  font-family: 'alfios' !important;
}
.v-application .text-md-h4 {
  font-family: 'alfios' !important;
}
.v-application .text-sm-h6 {
  font-family: 'alfios' !important;
}
.v-application .text-caption {
  font-family: 'alfios' !important;
}
</style>
