<template>
  <v-main>
    <!-- Appbar code start for big screens -->
    <v-app-bar bottom hide-on-scroll app class="grey lighten-3">
      <v-btn
        depressed
        height="110%"
        class="d-none d-md-block grey lighten-3"
        @click="$router.push('/')"
      >
        <v-img
          alt="Photography Logo"
          class="mr-2"
          contain
          src="../assets/logo2.png"
          transition="scale-transition"
          width="100"
        >
        </v-img>
      </v-btn>

      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn
            class="d-none d-md-block"
            text
            active-class="grey lighten-3"
            width="13%"
            height="110%"
            v-on="on"
            >Wedding</v-btn
          >
        </template>

        <v-list>
          <v-list-item @click="$router.push('/wedding').catch((err) => {})">
            <v-list-item-title class="text-center"
              >Thodoris Sofia</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="$router.push('/wedding2').catch((err) => {})">
            <v-list-item-title class="text-center"
              >Sevastianos Marianna
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn
            class="d-none d-md-block"
            text
            active-class="grey lighten-3"
            width="14%"
            height="110%"
            v-on="on"
            >Christening</v-btn
          >
        </template>

        <v-list>
          <v-list-item @click="$router.push('/christening').catch((err) => {})">
            <v-list-item-title class="text-center">Manolis</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="$router.push('/christening2').catch((err) => {})"
          >
            <v-list-item-title class="text-center">Nikitas</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn
            class="d-none d-md-block"
            text
            active-class="grey lighten-3"
            width="14%"
            height="110%"
            v-on="on"
            >Digital Albums</v-btn
          >
        </template>

        <v-list>
          <v-list-item
            @click="$router.push('/Digitalalbum').catch((err) => {})"
          >
            <v-list-item-title class="text-center"
              >Dimitris Voula</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            @click="$router.push('/Digitalalbum2').catch((err) => {})"
          >
            <v-list-item-title class="text-center"
              >Thodoris Sofia</v-list-item-title
            >
          </v-list-item>
          <v-list-item
            @click="$router.push('/Digitalalbum3').catch((err) => {})"
          >
            <v-list-item-title class="text-center">Aggelos</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="$router.push('/Digitalalbum4').catch((err) => {})"
          >
            <v-list-item-title class="text-center"
              >Alexandros</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn
            class="d-none d-md-block"
            text
            active-class="grey lighten-3"
            width="14%"
            height="110%"
            v-on="on"
          >
            Other Photo <br />Projects
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="$router.push('/other').catch((err) => {})">
            <v-list-item-title class="text-center"
              >Acropolis Vision</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="$router.push('/other2').catch((err) => {})">
            <v-list-item-title class="text-center"
              >Airbnb House</v-list-item-title
            >
          </v-list-item>
          <v-list-item @click="$router.push('/other3').catch((err) => {})">
            <v-list-item-title class="text-center"
              >Happy Family</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        class="d-none d-md-flex"
        text
        active-class="grey lighten-3"
        width="11%"
        height="110%"
        to="/about"
        >about</v-btn
      >

      <v-btn
        class="d-none d-md-flex"
        text
        active-class="grey lighten-3"
        width="12%"
        height="110%"
        to="/contact"
        >contact</v-btn
      >

      <a class="d-none d-md-block" href="//www.facebook.com/k.andrisphoto">
        <v-btn icon width="110">
          <v-icon color="black" large>mdi-facebook</v-icon>
        </v-btn>
      </a>
      <a class="d-none d-md-block" href="//www.instagram.com/k.andrisphoto/">
        <v-btn icon width="110">
          <v-icon color="black" large>mdi-instagram</v-icon>
        </v-btn>
      </a>

      <!-- Appbar code END for big screens -->

      <!-- Appbar code START for mobile -->
      <v-menu :close-on-content-click="false" v-model="isOpen">
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon
            class="d-md-none"
            light
            width="110"
            v-on="on"
          ></v-app-bar-nav-icon>
        </template>

        <v-list subheader tile dense>
          <v-list-item to="/" @click="isOpen = !isOpen">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="barText"> Home</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-list subheader tile dense>
          <v-list-group
            class="barText"
            v-for="item in items"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              v-for="subItem in item.items"
              :key="subItem.title"
              @click="
                $router.push(subItem.link);
                isOpen = !isOpen;
              "
            >
              <v-list-item-content>
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon>{{ subItem.action }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-list subheader tile dense>
          <v-list-item class="barText" to="/About" @click="isOpen = !isOpen">
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list class="barText" subheader tile dense>
          <v-list-item to="/Contact" @click="isOpen = !isOpen">
            <v-list-item-icon>
              <v-icon>mdi-card-account-mail</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <a class="d-md-none" href="//www.facebook.com/k.andrisphoto">
        <v-btn icon width="110">
          <v-icon color="black" large>mdi-facebook</v-icon>
        </v-btn>
      </a>
      <a class="d-md-none" href="//www.instagram.com/k.andrisphoto">
        <v-btn icon width="110">
          <v-icon color="black" large>mdi-instagram</v-icon>
        </v-btn>
      </a>
    </v-app-bar>
  </v-main>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      items: [
        {
          action: "mdi-home-heart",
          title: "Wedding",
          items: [
            { title: "Thodoris Sofia", link: "/wedding" },
            { title: "Sevastianos Marianna", link: "/wedding2" },
          ],
        },
        {
          action: "mdi-human-female-girl",
          title: "Christening",
          items: [
            { title: "Manolis", link: "/christening" },
            { title: "Nikitas", link: "/christening2" },
          ],
        },
        {
          action: "mdi-book-open-page-variant-outline",
          title: "Digital Albums",
          items: [
            { title: "Dimitris Voula", link: "/digitalalbum" },
            { title: "Thodoris Sofia", link: "/digitalalbum2" },
            { title: "Aggelos", link: "/digitalalbum3" },
            { title: "Alexandros", link: "/digitalalbum4" },
          ],
        },
        {
          action: "mdi-basket-outline",
          title: "Other Projects",
          items: [
            { title: "Acropolis Vision", link: "/other" },
            { title: "Airbnb House", link: "/other2" },
            { title: "Happy Family", link: "/other3" },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.v-btn {
  font-family: typewriter;
  font-size: 110% !important;
  text-align: center;
}
.v-application {
  font-family: "typewriter" !important;
}
.v-headline {
  font-family: "typewriter" !important;
}
.v-application .headline {
  font-family: "typewriter" !important;
}
.barText {
  font-family: "typewriter" !important;
}
.text-center {
  font-family: "typewriter" !important;
}
</style>
