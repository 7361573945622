<template>
  <div class="contact" >
    <v-img src="../assets/img13.jpg" height="160px" contain @click="$router.push('/')">
      <v-spacer></v-spacer> <v-divider></v-divider>
      <v-row align="end" class="lightbox white--text pa-2 fill-height">
  
      </v-row>
    </v-img>
    <v-row justify="center">
      <v-col  cols="8" md="6" xs="12">
        <div class="contactText mt-5">
            <p class="text-left"> Ανδρής Κώστας</p>
             <p class="text-left mt"> Φωτογράφος Νάξος - Αθήνα</p>
              <p id="contactInfo" class="text-left mt-1"> Τηλέφωνο: +30 694 41 71 838
              <br> Email: k.andrisphoto@gmail.com</p> 
              <a class="Fblink" v-bind:href="url">Facebook: facebook.com/k.andrisphoto</a>
        </div>
    </v-col>
    </v-row>
   
      <v-row dense justify="center" >
        <v-col  cols="8" md="6" xs="12">
          <v-form  justify="center"  ref="form" v-model="valid" lazy-validation>
            <v-text-field dense
            class="contactText" 
              v-model="name"
              :rules="nameRules"
              label="Full Name"
              required
            ></v-text-field>
            <v-text-field dense
            class="contactText" 
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
            <v-textarea dense 
            class="contactText" 
              v-model="message"
              :rules="messageRules"
              label="Message"
              required
            ></v-textarea>
            <v-btn 
              :disabled="!valid"
              color="success"
              class="mr-4 contactText"
              @click="validate"
              >Submit</v-btn
            >
            <v-btn color="error" class="mr-4 contactText" @click="reset">Reset</v-btn>
          </v-form>
            <v-snackbar class="contactText" 
              v-model="snackbar"
                 >
                {{ text }}
                    <template v-slot:action="{ attrs }">
                       <v-btn
                         color="pink"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
             </v-snackbar>
        </v-col>
      </v-row>
   
  </div>
</template>


<script>

import emailjs from 'emailjs-com';
import '../app.scss';

export default {
  name: "Contact",
  data: () => ({
    valid: true,
    snackbar: false,
      text: `Thank you for your message.`,
      url: "https://www.facebook.com/k.andrisphoto",
     
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 15) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    message: "",
    messageRules: [
      (v) => !!v || "Message is required",
      (v) => (v && v.length >= 10) || "Message must be more than 10 characters",
    ],
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
         var templateParams = {
            name: this.name,
          email: this.email,
          message: this.message
         };
        emailjs.send('service_er5hy1g', 'template_z0dlh39',templateParams,
        'user_MYNsVx1dYjta6Ismsb5pR',).then(
          this.snackbar = true
         );
         this.$refs.form.reset();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped>

.v-application {
  font-family: "typewriter" !important;
}
.v-headline {
  font-family: "typewriter" !important;
}
.v-application .headline {
  font-family: "typewriter" !important;
}
.contactText{
   font-family: 'alfios' !important;
   font-size:initial;
   font-weight: 600 ;

}
.Fblink{
  color: black;
   text-decoration: none !important; 
  
}
.v-application p {
  margin-bottom: 0 !important;
  
}
</style>

