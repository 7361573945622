<template>
  <div class="Wedding2">
    <v-row>
      <v-col
        v-for="(image, idx) in images"
        :key="idx"
        class="d-flex child-flex flex-column align-center mb-5"
        cols="12"
        @click="() => show(idx)"
      >
        <v-lazy
          v-model="isActive"
          :options="{
            threshold: 0.5,
          }"
          min-height="200"
          transition="fade-transition"
        >
          <v-img
            max-width="100%"
            :src="image"
            :lazy-src="lazyimage[idx]"
            contain
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-lazy>
      </v-col>
      <vue-easy-lightbox
        :visible="visible"
        :index="index"
        :imgs="images"
        :moveDisabled="false"
        @hide="handleHide"
        style="background-color: rgba(0, 0, 0, 0.9); zoom: 130%"
      />
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Wedding2',
  data() {
    return {
      visible: false,
      isActive: false,
      index: 0, // default
    };
  },
  methods: {
    show(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
      this.index = 0;
    },
  },
  computed: {
    images() {
      const array = [];
      let currentImage = '';
      for (let i = 1; i <= 20; i++) {
        currentImage = require(`../assets/wedding/sev-mar/${i}.jpg`);
        array.push(currentImage);
      }
      return array;
    },
    lazyimage() {
      const array = [];
      let lazyImage = '';
      for (let j = 1; j <= 20; j++) {
        lazyImage = require(`../assets/wedding/sev-marlazy/${j}.png`);
        array.push(lazyImage);
      }
      return array;
    },
  },
};
</script>
