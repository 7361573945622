<template>
  <v-app class="grey">
  
    <router-view></router-view>
    <v-main></v-main>  <Navbar />
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "App",
  components: { Navbar },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
.theme--light.v-application {
  background: rgb(243, 243, 243);
}

</style>

<style scoped>
@font-face {
  font-family: "typewriter";
  src: url(fonts/typewriter.ttf);
}
  .v-application--wrap {
    min-height: auto;
  }
</style>


