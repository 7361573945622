<template>
  <v-main class="home">
    <v-layout justify-space-around mt-10>
      <v-card elevation="24" :width="windowWidth">
        
          <!-- Carousel for big screens -->

        <v-carousel v-if="window > 900"
          interval="9000"
          height="auto"
          reverse-transition="fade-transition"
          transition="fade-transition"
          :show-arrows="showArrows"
          :hide-delimiters="hideDelimiters"
          :cycle="cycle"
        >
          <v-carousel-item
            v-for="i in 6"
            :key="i"
            eager
          >
           <v-img  :src="require(`../assets/home/${i}.jpg`)" eager />
          </v-carousel-item>
        </v-carousel>

            <!-- Carousel for small screens -->
         <v-carousel v-else
          interval="9000"
          height="549"
          :show-arrows="showArrows"
          :hide-delimiters="hideDelimiters"
          :cycle="cycle"
        >
          <v-carousel-item
            v-for="i in 7"
            :key="i"
            eager
          > <v-img  :src="require(`../assets/home/m${i-1}.jpg`)" eager />
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-layout>
  </v-main>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      window: window.innerWidth,
      showArrows: true,
      hideDelimiters: true,
      cycle: true,
      windowWidthvalue: "100%"
    };
  },
  computed: {
    windowWidth() {
      if (window.innerWidth>900) return "60%"
      else return "100%"
    }
  }
};
</script>

<style scoped>
.v-main  {
  padding: 0 !important;
}

</style>